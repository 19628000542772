import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import Button from "components/Button"

import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const Confirmation = () => {
  const { t } = useLocale()

  return (
    <section className="contact-confirmation">
      <div className="container-fluid">
        <SectionHeader
          title={t("Dziękujemy")}
          content={t("za kontakt")}
          type="white"
        />
        <div className="row">
          <div className="col-xl-11 offset-xl-1">
            <motion.div className="not-found-content__inner" {...fadeAnimation}>
              <h2>{t("Wiadomość została wysłana.")}</h2>
              <p>
                {t(
                  "Dziękujemy za kontakt. Twoja wiadomość została do nas wysłana. Nasi specjaliści skontaktują się z Tobą najszybciej jak to możliwe. W międzyczasie zachęcamy do zapoznania się z ofertą naszych tapet."
                )}
              </p>
              <Button to={t("/")} className="button--fill">
                {t("Strona główna")}
              </Button>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Confirmation
