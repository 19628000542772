import "./styles.scss"

import React, { useState } from "react"

import { useLocale } from "context/locale"

const Input = ({
  label,
  id,
  type,
  name,
  value,
  disabled,
  onChange,
  onBlur,
  placeholder,
  required,
  rows,
  className,
  ref,
  withIcon,
  error,
  errors,
  onKeyDown,
}) => {
  const { t } = useLocale()
  const [filled, setFilled] = useState(false)

  const handleFocus = () => {
    setFilled(true)
  }

  const checkInput = ({ target: { value } }) => {
    if (value.length > 0) {
      setFilled(true)
    } else {
      setFilled(false)
    }
  }

  const onBlurHandler = e => {
    checkInput(e)
    if (!!onBlur) {
      onBlur()
    }
  }

  return (
    <div
      className={`input${filled ? " fill" : ""}${
        className ? ` ${className}` : ""
      }${withIcon ? ` input--with-icon ${withIcon}` : ""}${
        errors?.hasOwnProperty(name) ? " error" : ""
      }`}
    >
      {label && (
        <label className="input__label" htmlFor={name}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {withIcon && <span className="input__icon"></span>}
      {type === "textarea" ? (
        <textarea
          className="input__input"
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={onBlurHandler}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          rows={rows}
        />
      ) : (
        <input
          className={`input__input${
            error === "error" ? " input__input-error" : ""
          }`}
          type={type}
          onKeyDown={onKeyDown}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={onBlurHandler}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          ref={ref}
        />
      )}
      {error === "error" && (
        <div className="input__error">{t("Pole wymagane")}</div>
      )}
      {errors && errors?.hasOwnProperty(name) && (
        <span className="input__error">{t(errors[name])}</span>
      )}
    </div>
  )
}

export default Input
