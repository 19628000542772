/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import { withPrefix } from "gatsby"
import { motion } from "framer-motion"

import ContactForm from "components/ContactForm"
import AnimatedMask from "components/AnimatedMask"

import mapStyle from "utils/mapStyle"
import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const center = {
  lat: 54.4791753,
  lng: 17.0434673,
}

const social = [
  {
    url: "https://www.facebook.com/wallcraftpl/",
    icon: require("assets/icons/facebook.svg").default,
  },
  {
    url: "https://www.instagram.com/wallcraft_nowoczesne_tapety/",
    icon: require("assets/icons/instagram.svg").default,
  },
  {
    url: "https://pl.pinterest.com/wallcraft_nowoczesne_tapety/",
    icon: require("assets/icons/pinterest.svg").default,
  },
]

const Content = ({ zoom = 14 }) => {
  const { t } = useLocale()
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBs_dYn2cnkyG70J6RI6x3mi7-Z_nSbA7g",
  })

  return (
    <section className="contact-content">
      <motion.div className="container-fluid" {...fadeAnimation}>
        <div className="row">
          <div className="col-md-5">
            <address>
              <strong>Wallcraft</strong>
              <br />
              {t("ul.")} Kaszubska 45
              <br />
              76-200 Słupsk
            </address>
            <div className="contact-content__inner">
              <h3>
                <a href="tel:+48 535 749 401">+48 535 749 401</a>
              </h3>
              <h3>
                <a href="mailto:biuro@wallcraft.com.pl">
                  biuro@wallcraft.com.pl
                </a>
              </h3>
            </div>
            <div className="contact-content__social">
              {social?.map((item, index) => (
                <a href={item.url} key={index}>
                  <img src={item.icon} alt="" />
                </a>
              ))}
            </div>
          </div>
          <div className="col-md-7">
            <ContactForm />
          </div>
        </div>
      </motion.div>

      <div className="google-map">
        <AnimatedMask />
        {isLoaded ? (
          <GoogleMap center={center} zoom={zoom} options={{ styles: mapStyle }}>
            <Marker icon={withPrefix("/pointer.svg")} position={center} />
          </GoogleMap>
        ) : null}
      </div>
    </section>
  )
}

export default Content
